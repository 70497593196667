import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache
} from '@apollo/client';
import { createFragmentRegistry } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';

import { StoreFrontAPIConfig } from '@/shared/constants/shopify.js';
import { storefrontGlobalFragments } from '@/shared/graphql/storefront/fragments';
import { ensureTrailingSlash } from '@/shared/utils/urls';

/**
 * Enriches context with a Shopify Public Access Token
 * @param accessToken Shopify Public Access Token
 * @returns ApolloLink
 */
const enrichLink = (accessToken: string) =>
  setContext((_, { headers }) => ({
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': accessToken
    }
  }));

/**
 * Ensures an explicit Storefront endpoint url
 * @param url Either raw Shopify domain, or an explicit graphql.json url
 * @returns Explicit graphql.json url
 */
const ensureStorefrontEndpointUrl = (url: string) =>
  !url.endsWith('.json')
    ? `${ensureTrailingSlash(url)}api/${
        StoreFrontAPIConfig.VERSION
      }/graphql.json`
    : url;

/**
 * Initialize ApolloClient for Shopify Storefront
 * @param url - Shopify url or complete Storefront GraphQL JSON url
 * @param accessToken Shopify Public Access Token
 * @returns ApolloClient
 */
export const initStorefrontApolloClient = (url: string, accessToken: string) =>
  new ApolloClient({
    link: ApolloLink.from([
      enrichLink(accessToken),
      createHttpLink({ uri: ensureStorefrontEndpointUrl(url) })
    ]),
    cache: new InMemoryCache({
      fragments: createFragmentRegistry(storefrontGlobalFragments)
    })
  });

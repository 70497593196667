import { gql } from '@/__generated__/gateway';

export const postQuery = gql(`
  query Post($postId: ID!) {
    community {
      post(id: $postId) {
        id
        body
        images
        published_at
        comments_count
        own_reaction
        author {
          id
          name
          username
          display_name
          avatar_url
          is_following
        }
        reaction_counts {
          love
          in_love
          smile
          cool
          haha
          wow
        }
        context {
          kind
          entity
          entity_id
          language
        }
        teaser {
          site_name
          url
          title
          type
        }
      }
    }
  }
`);

export const postsQuery = gql(`
  query Posts($cursor: String, $limit: Int, $context: ContextInput, $userId: ID) {
    community {
      posts(context: $context, cursor: $cursor, limit: $limit, user_id: $userId) {
        cursor
        limit
        total
        posts {
          id
          body
          images
          published_at
          comments_count
          own_reaction
          author {
            id
            name
            username
            display_name
            avatar_url
            is_following
          }
          reaction_counts {
            love
            in_love
            smile
            cool
            haha
            wow
          }
          context {
            kind
            entity
            entity_id
            language
          }
          teaser {
            site_name
            url
            title
            type
          }
        }
      }
    }
  }
`);

export const commentsQuery = gql(`
  query Comments($postId: ID!, $limit: Int, $cursor: String) {
    community {
      comments(post_id: $postId, limit: $limit, cursor: $cursor) {
        cursor
        limit
        comments {
          post_id
          body
          id
          images
          author {
            id
            name
            display_name
            username
            avatar_url
            is_following
          }
          reaction_counts {
            love
            in_love
            smile
            cool
            haha
            wow
          }
          own_reaction
          reaction_counts {
            love
            in_love
            smile
            cool
            haha
            wow
          }
          published_at
        }
      }
    }
  }
`);

export const reactionsQuery = gql(`
  query Reactions($input: GetReactionsInput!) {
    community {
      reactions(input: $input) {
        reactions {
          author {
            id
            name
            username
            display_name
            avatar_url
            is_following
          }
          label
        }
        cursor
        limit
      }
    }
  }
`);

export const profileQuery = gql(`
  query Profile($userId: ID!) {
    community {
      profile(user_id: $userId) {
        id
        followers_count
        following_count
        user {
          id
          avatar_url
          display_name
          name
          username
          is_following
          country
          bio
        }
      }
    }
  }
`);

export const profileNameCheckQuery = gql(`
  query ProfileNameCheck($username: String!) {
    users {
      usernameCheck(username: $username) {
        available
      }
    }
  }
`);

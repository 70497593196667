import { gql } from '@apollo/client';

export const storefrontGlobalFragments = gql(`
  fragment ShopifyPrice on MoneyV2 {
    amount
    currencyCode
  }


  fragment ShopifyDiscountValue on PricingValue {
    ... on MoneyV2 {
        ...ShopifyPrice
    }
    ... on PricingPercentageValue {
        percentage
    }
  }

  fragment ShopifyDiscountApplication on DiscountApplication {
    targetSelection
    targetType
    value {
        ...ShopifyDiscountValue
    }
    ... on AutomaticDiscountApplication {
        title
    }
    ... on DiscountCodeApplication {
        code
    }
    ... on ManualDiscountApplication {
        description
        title
    }
    ... on ScriptDiscountApplication {
        title
    }
  }

  fragment ShopifyDiscountAllocation on DiscountAllocation {
    allocatedAmount {
        ...ShopifyPrice
    }
    discountApplication {
        ...ShopifyDiscountApplication
    }
  }

  fragment ShopifyOrderLineItemDiscount on OrderLineItem {
    currentQuantity
    quantity
    discountAllocations {
        ... ShopifyDiscountAllocation
    }
  }`);

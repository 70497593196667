import axios from 'axios';

import { useUserStore } from '../pinia/modules/user';

export const getToken = async (isRetry = false) => {
  let token;

  try {
    const authTokenApi = '/apps/cognito/api/auth/token';
    const sessionResponse = await axios.get(authTokenApi);

    if (sessionResponse.data.token) {
      token = sessionResponse.data.token;
    }
    return token;
  } catch (error) {
    if (import.meta.env.DEV && !isRetry) {
      token = import.meta.env.VITE_CID;
      const userStore = useUserStore();
      userStore.setCustomer(token);
    }

    return token;
  }
};
